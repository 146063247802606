<template>
    <div :class=" mobileClass ">
        <!--		<button @click.stop="moveTo">移动随机某点</button>-->
        <!--		<button @click.stop="start">开始自动旋转</button>-->
        <!--		<button @click.stop="stop">停止自动旋转</button>-->
        <div @click="roataionStart" id="map"></div>
    </div>
</template>

<script>
import {MeshoptDecoder} from '@/assets/libs/decoder.js';
// 定时器动画句柄
let scene, camera, renderer, orbitcontrols,
    earthBall, // 地球实体
    spherical,
    light,
    hexagon,
    point,//光标
    arrow,//箭头
    dom, // 容器
    tween, // 补间动画
    rotaionFlag = false,
    handle = null,
    rotationMatrix = new THREE.Matrix4(),
    rotationMatrix1 = new THREE.Matrix4(),
    targetQuaternion = new THREE.Quaternion(),
    targetQuaternion1 = new THREE.Quaternion();
export default {
    props: {
        position: {
            type: Object,
            default() {
                return {
                    lng: 180,
                    lat: 0
                }
            }
        },
    },
    data() {
        return {
            proportion: 7,
            mobileClass: 'map-content'
        }
    },
    watch: {
        position: {
            handler(value) {
                this.moveTo(value)
            },
            deep: true
        }
    },
    mounted() {
        let w = document.documentElement.offsetWidth || document.body.offsetWidth;
        if (w < 1000) { // 手机
            this.mobileClass = 'mobile-map-content'
        }
        this.init()
        // 窗口resize事件
        // window.onresize = function () {
        // 	// 重新初始化尺寸
        // 	camera.aspect = dom.clientWidth / dom.clientHeight
        // 	camera.updateProjectionMatrix()
        // 	renderer.setSize(dom.clientWidth, dom.clientHeight)
        // }
    },
    methods: {
        roataionStart() {
            // rotaionFlag =
            orbitcontrols.autoRotate == !0 ? orbitcontrols.autoRotate = !1 : orbitcontrols.autoRotate = !0
        },
        start() {
            orbitcontrols.autoRotate = true;
        },
        stop() {
            orbitcontrols.autoRotate = false;
        },
        init() {
            dom = this.$el.querySelector('#map')
            let w = document.documentElement.offsetWidth || document.body.offsetWidth;
            const width = dom.clientWidth
            // const height = dom.clientHeight
            let height = null
            if (w < 1000) { // 手机
                height = 450
            } else {
                height = dom.clientHeight
            }
            
            // 初始化场景
            scene = new THREE.Scene()
            // 设置光线
            
            // scene.add(new THREE.HemisphereLight('#ffffff', '#ffffff', 1))//半球光
            scene.add(new THREE.AmbientLight('#ffffff', 0.6))//环境光
            scene.add(new THREE.DirectionalLight('#ffffff', 0.7))//点光源
            // const directionalLight = new THREE.PointLight('#ffffff', 1, 100)
            // directionalLight.position.set(0, 20, 0);
            
            // scene.add(directionalLight)//点光源
            // 初始化相机
            camera = new THREE.PerspectiveCamera(20, width / height, 1, 10000)
            // 设置相机位置
            // camera.position.set(0, 0, 7)//控制地球屏幕占比
            let proption = null
            
            if (w < 1000) { // 手机
                proption = 9
            } else {
                proption = 7
            }
            camera.position.set(0, 0, proption)//控制地球屏幕占比
            
            renderer = new THREE.WebGLRenderer({
                precision: "highp",
                premultipliedAlpha: false,
                stencil: false,
                // preserveDrawingBuffer: true, //是否保存绘图缓冲
                alpha: true, // 是否透明
                antialias: true // 抗锯齿
            })
            
            renderer.sortObjects = true;
            renderer.autoClear = true
            renderer.gammaInput = true;
            renderer.gammaOutput = true;
            renderer.gammaFactor = 1.8
            
            // 设置窗口尺寸
            renderer.setSize(width, height)
            dom.appendChild(renderer.domElement)
            // 初始化控制器
            orbitcontrols = new THREE.OrbitControls(camera, renderer.domElement)
            //禁止缩放
            orbitcontrols.enableZoom = false;
            //是否自动旋转
            orbitcontrols.autoRotate = true;
            //是否开启右键拖拽
            orbitcontrols.enablePan = false;
            //禁止键盘控制
            orbitcontrols.enableKeys = false;
            // 使动画循环使用时阻尼或自转 意思是否有惯性
            orbitcontrols.enableDamping = true
            // 动态阻尼系数 就是鼠标拖拽旋转灵敏度
            orbitcontrols.dampingFactor = 1
            //设置相机距离原点的最远距离
            orbitcontrols.minDistance = 2;
            //设置相机距离原点的最远距离
            orbitcontrols.maxDistance = 15;
            
            this.earthInit()
            
            // this.pointInit()
            
            // this.arrowInit()
            this.createCone()
            this.createHexagon()
            
            
            this.animation()
        },
        moveTo({lng = 180, lat = 0}) {
            //精度(0,360) 纬度(-90,90)
            // console.log('okkkkk====', lng, lat);
            // console.log('精度: ', lng);
            // console.log('维度: ', lat);
            if (!spherical) {
                spherical = new THREE.Spherical();
            }
            spherical.radius = 1;//半径
            
            spherical.theta = -lng * Math.PI / 180//经度
            spherical.phi = Math.acos(lat / 90);//纬度
            // const a = Math.random() * 100 * Math.PI / 180
            // spherical.theta = a;
            // spherical.phi = Math.acos((2 * Math.random()) - 1);
            light.position.setFromSpherical(spherical);//更新点位
            hexagon.position.setFromSpherical(spherical);//更新点位
            
            rotationMatrix.lookAt(new THREE.Vector3(0, 0, 0), light.position, new THREE.Vector3(0, 0, 1));
            rotationMatrix1.lookAt(new THREE.Vector3(0, 0, 0), hexagon.position, new THREE.Vector3(0, 0, 1));
            targetQuaternion.setFromRotationMatrix(rotationMatrix);
            targetQuaternion1.setFromRotationMatrix(rotationMatrix1);
            //旋转地球视角
            this.viewTo({
                x: 0, y: -spherical.theta + 0.3, z: 0
            })
            
        },
        pointInit() {
            const targetGeometry = new THREE.SphereGeometry(0.01);
            const targetMaterial = new THREE.MeshBasicMaterial({color: 0xffffff, opacity: 1});
            point = new THREE.Mesh(targetGeometry, targetMaterial);
            scene.add(point);
        },
        arrowInit() {
            const geometry = new THREE.ConeGeometry(0.1, 0.2, 8);
            geometry.rotateX(Math.PI * 0.5);
            // const material = new THREE.MeshNormalMaterial();
            // const material = new THREE.MeshLambertMaterial({ color: 0xff0000}); // 这里换圆锥颜色
            const material = new THREE.MeshLambertMaterial({color: 0xcccccc}); // 这里换圆锥颜色
            
            arrow = new THREE.Mesh(geometry, material);
            scene.add(arrow);
            
        },
        createCone() {
            let texture = new THREE.TextureLoader().load('./imgs/lightray.jpg'),
                material = new THREE.MeshBasicMaterial({
                    map: texture,
                    transparent: true,
                    depthTest: true,
                    side: THREE.DoubleSide,
                    blending: THREE.AdditiveBlending
                }),
                height = 0.7,
                geometry = new THREE.PlaneGeometry(0.1, height),
                matrix1 = new THREE.Matrix4;
            light = new THREE.Mesh(geometry, material);
            matrix1.makeRotationX(Math.PI / 2)
            matrix1.setPosition(new THREE.Vector3(0, 0, height / -2))
            geometry.applyMatrix(matrix1)
            let plane2 = light.clone()
            plane2.rotation.z = Math.PI / 2
            light.add(plane2)
            light.lookAt(0, 0, 0)
            scene.add(light)
        },
        createHexagon() {
            // const color = 0xffff13
            const color = 0xffffff
            let hexagonPlane = new THREE.CircleGeometry(0.05, 6)
            let materialPlane = new THREE.MeshBasicMaterial({
                color: color,
                side: THREE.DoubleSide,
                opacity: 1
            })
            hexagon = new THREE.Mesh(hexagonPlane, materialPlane)
            hexagon.lookAt(0, 0, 0)
            // circlePlane.rotation.y = Math.PI / 2
            scene.add(hexagon)
        },
        // 创建球
        earthInit() {
            THREE.DRACOLoader.setDecoderPath('./wasm/')
            let loading = this.$loading({
                background: '#000000',
                target: document.querySelector('.map-content'),
                text: '星舰登陆中...',
            })
            new THREE.GLTFLoader()
                .setDRACOLoader(new THREE.DRACOLoader())
                .setMeshoptDecoder(MeshoptDecoder)
                .load(
                    // "http://47.94.8.109/earth/models/earth.glb",
                    "./models/003.glb",//直接替换文件就可以了
                    function (gltf) {
                        earthBall = gltf.scene
                        earthBall.rotation.y = Math.PI
                        scene.add(earthBall);
                        loading.close()
                    },
                    undefined,
                    function (e) {
                        console.error(e);
                    }
                );
            
        },
        // 视图飞向position={ x, y, z }
        viewTo(position) {
            const scenePosition = {
                x: scene.rotation.x,
                y: scene.rotation.y,
                z: scene.rotation.z
            }
            tween = new TWEEN.Tween(scenePosition).to(position, 1000)
            tween.onUpdate(function () {
                scene.rotation.x = scenePosition.x
                scene.rotation.y = scenePosition.y
                scene.rotation.z = scenePosition.z
            })
            tween.onComplete(function () {
                tween.stop()
            })
            
            tween.start()
            this._autoRotate(false)
        },
        // 是否自动旋转
        _autoRotate(boolean = true) {
            orbitcontrols.autoRotate = boolean
        },
        // 执行函数
        animation(time) {
            if (handle) {
                cancelAnimationFrame(handle)
            }
            renderer.clearDepth()
            
            light.quaternion.rotateTowards(targetQuaternion, 0.1);
            hexagon.quaternion.rotateTowards(targetQuaternion1, 0.1);
            
            renderer.render(scene, camera)
            
            this.render(time)
            handle = requestAnimationFrame(this.animation)
        },
        render(time) {
            orbitcontrols.update()
            if (tween && tween.isPlaying()) {
                tween.update(time)
            }
        }
    },
    beforeDestroy() {
        cancelAnimationFrame(handle)
    }
}
</script>

<style scoped lang="scss">
//body{
//	background-color: #fff!important;
//}
.map-content {
    width: 100%;
    height: 100vh;
    background: url('../assets/images/test1.gif') 100% 100%/100% 100% no-repeat;
    
    #map {
        height: inherit;
    }
}

.mobile-map-content {
    max-width: 100%;
    height: auto !important;
    background: url('../assets/images/test1.gif') 100% 100% no-repeat;
    
    #map {
        height: inherit;
    }
}
</style>
